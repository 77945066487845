body {
  font-size: 14px;
  color: #000;
  margin: 0;
  font-weight: 400;
  font-family: 'Work Sans', sans-serif;
  background-color: #f7f7f7;
  overflow-y: scroll;
  height: 100%;
}

html {
  height: 100%;
}

* {
  outline: none;
}

a {
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  text-emphasis: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.primary {
  color: #2191F7;
  word-wrap: break-word;
}

.primary-font {
  font-size: 22px;
  color: #000;
  letter-spacing: 0.5px;
}

.primary-font span {
  font-size: 13px;
  color: #444;
  vertical-align: baseline;
}

.breadcrum a {
  display: inline-block;
  line-height: normal;
  vertical-align: baseline;
  text-decoration: none;
}

.breadcrum a span {
  color: #007ad9;
}

.secondary-font {
  font-size: 15px;
  color: #000;
}

.bold-font-size-n {
  font-size: 13px;
  color: #000;
  font-weight: 500;
  display: block;
  margin: 0;
}

.bold-font-size-n.size {
  font-size: 14px;
  line-height: 19px;
}

.thin-font {
  font-size: 16px;
  font-weight: 400;
  color: #888;
  display: block;
  word-break: break-word;
}

.normal,
.urgent {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.urgent {
  color: #ce2339;
}

.normal {
  color: #43bb43;
}

.thin-font.size,
.thin-font.size.normal,
.thin-font.size.urgent {
  font-size: 14px;
  line-height: 19px;
}

.bmargin50 {
  margin-bottom: 50px;
}

.bmargin35 {
  margin-bottom: 35px;
}

.bmargin30 {
  margin-bottom: 30px;
}

.bmargin20 {
  margin-bottom: 20px;
}

.bmargin15 {
  margin-bottom: 15px;
}

.bmargin10 {
  margin-bottom: 10px;
}

.bmargin5 {
  margin-bottom: 5px;
}

.tmargin50 {
  margin-top: 50px;
}

.tmargin42 {
  margin-top: 42px;
}

.tmargin40 {
  margin-top: 40px;
}

.tmargin35 {
  margin-top: 35px;
}

.tmargin30 {
  margin-top: 30px;
}

.tmargin22 {
  margin-top: 22px;
}

.tmargin20 {
  margin-top: 20px;
}

.tmargin15 {
  margin-top: 15px;
}

.tmargin10 {
  margin-top: 10px;
}

.tmargin5 {
  margin-top: 5px;
}

.lmargin10 {
  margin-left: 10px;
}

.lmargin15 {
  margin-left: 15px;
}

.lmargin40 {
  margin-left: 40px;
}

.lmargin20 {
  margin-left: 20px;
}

.rmargin10 {
  margin-right: 10px;
}

img {
  max-width: 100%;
}

.size20 {
  font-size: 20px;
}

.size18 {
  font-size: 18px;
}

.size16 {
  font-size: 16px;
}

.size9 {
  font-size: 9px;
}

.lesser-than-font {
  font-size: 85%;
  font-weight: 400;
}

.lmargin5 {
  margin-left: 5px;
}

.bmargin0 {
  margin-bottom: 0;
}

.weight700 {
  font-weight: 700;
}

.weight600 {
  font-weight: 600;
}

.weight500 {
  font-weight: 500;
}

.export-btn {
  min-width: 0;
  height: 44px;
}

.info-font {
  font-size: 20px;
  font-weight: 500;
  line-height: 0.75;
  letter-spacing: 0.2px;
  position: relative;
}

.bottom-line {
  position: relative;
  padding: 0 0 7px;
}

.font600 {
  font-weight: 600
}

.bottom-line::after {
  content: '';
  width: 380px;
  position: absolute;
  border-top: 1px solid #ccc;
  left: 0;
  bottom: 0;
}

.attachement {
  word-break: break-word;
}

.downloads {
  color: dodgerblue;
}

.new-product-tag {
  width: fit-content;
  background-color: #d6f8d4;
  color: #66ac64;
  font-weight: bold;
  font-size: smaller;
  margin-bottom: 2px;
  padding: 2px 5px;
}

.nav-tabs .nav-link {
  border-width: 3px 1px 1px;
  padding: 10px 30px;
  letter-spacing: 1px;
  font-size: 15px;
  background-color: #fff;
  border-color: #fff;
  border-style: solid;
  border-right-color: 1px solid #f1f1f1;
}

.rfqsection {
  background: #ECF5FF;
  text-align: center;
}

.rfqsection:hover {
  background: #ECF5FF;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: #fff;
}

.currency-width {
  width: 100%;
  color: black;
  font-weight: 700;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  /* height: 45px; */
}

.nav-tabs .nav-link span {
  color: #ccc;
}

.nav-tabs .nav-link.active {
  border-width: 3px 1px 1px;
  border-color: #ce2339 #f1f1f1 #fff;
}

.nav-tabs .nav-item:first-child .nav-link {
  border-left-width: 0;
}

.nav-tabs {
  border: none;
}

.nav-tabs .nav-link.active span {
  color: #000;
}

.toast-top-center {
  top: 20px !important;
}

.swal2-popup.small-design {
  width: 370px;
}

.swal2-popup.small-design .swal2-title {
  font-size: 22px;
}

.swal2-popup.small-design .swal2-content {
  color: #999;
  font-size: 14px;
  font-weight: 500;
}

.swal2-popup.small-design .swal2-icon.swal2-success {
  margin: 0;
  transform: scale(0.8);
}

.brd-crm-arrow {
  margin: 0 5px;
}

.small-font {
  font-size: 13px;
}

.more-actions .dropdown-menu {
  left: auto;
  right: 0;
  border: none;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  top: 120%;
}

.more-actions.open .dropdown-menu {
  display: block;
}

.datatable-row-left .more-actions .dropdown-menu {
  left: 26px;
  right: auto;
  display: block;
  opacity: 0;
  visibility: hidden;
  border: none;
  top: 50%;
  margin-top: 0;
  padding: 0;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
  transform: translate(0, -50%);
  transition: all 0.2s ease 0s;
}

.ngx-datatable.material .datatable-body .progress-linear {
  z-index: 100;
}

.datatable-row-left .more-actions.open .dropdown-menu,
.ngx-datatable.material .datatable-body .datatable-row-right .more-actions.open .dropdown-menu {
  left: 30px;
  visibility: visible;
  width: 220px;
  opacity: 1;
}

.datatable-row-left .more-actions .dropdown-menu a {
  white-space: nowrap;
}

.ngx-datatable.scroll-horz .datatable-body {
  /* min-height: 530px !important; */
  overflow-x: hidden !important;
  border-left: 1px solid #ececec;
  border-right: 1px solid #ececec;
}

.ngx-datatable.material .datatable-body .datatable-row-center .datatable-body-cell {
  border-right: 1px solid #ececec;
}

.ngx-datatable.material .datatable-header .datatable-row-left,
.ngx-datatable.material .datatable-body .datatable-row-left,
.ngx-datatable.material .datatable-body .datatable-row-right {
  border-right: 1px solid #ececec !important;
  background-image: none !important;
}

.ngx-datatable.material.listing .datatable-header .datatable-row-left,
.ngx-datatable.material.listing .datatable-body .datatable-row-left,
.ngx-datatable.material .datatable-header .datatable-row-right,
.ngx-datatable.material .datatable-body .datatable-row-right {
  width: 25px !important;
}

.ngx-datatable.material.new-request .datatable-body .datatable-row-left,
.ngx-datatable.material.new-request .datatable-body .datatable-row-left {
  border-right: 0 !important;
}

.ngx-datatable.material .datatable-header .datatable-row-left,
.ngx-datatable.material .datatable-header .datatable-row-right {
  border-right: 0 !important;
  background-color: #f5fbff !important;
}

.ngx-datatable.material .datatable-header .datatable-row-right,
.ngx-datatable.material .datatable-body .datatable-row-right {
  border-right: 0 !important;
  position: absolute;
  right: 0;
  background-color: transparent !important;
}

.ngx-datatable.material .datatable-header .datatable-row-right {
  background-image: none !important;
  position: fixed;
  transform: translate3d(-16px, 0px, 0px) !important;
}

.ngx-datatable.material .datatable-header .datatable-row-right .datatable-header-cell {
  border-bottom: 1px solid #ececec !important;
  background: #f5fbff;
  padding: 6px 0;
  top: -1px;
}

.ngx-datatable.material .datatable-header .datatable-row-left .datatable-header-cell,
.ngx-datatable.material .datatable-header .datatable-row-center .datatable-header-cell:last-child {
  border-right: 0;
}

.ngx-datatable.material .datatable-body .datatable-row-right .more-actions.open .dropdown-menu {
  left: unset;
  right: 40px;
}

.detail-actions .btn {
  min-width: 0;
  padding-left: 15px;
  padding-right: 15px;
}

.detail-actions .more-btn {
  padding-left: 10px;
  padding-right: 10px;
}

.detail-actions {
  /* position: absolute;
  right: 0;
  top: -45px; */
  z-index: 3;
}

.status-box .status-tag {
  font-size: 11px;
  font-weight: 500;
  line-height: 21.5px;
  display: inline-block;
  vertical-align: top;
  margin-left: 15px;
  height: 23px;
  padding: 0px 8px;
  border-radius: 7px;
  border: solid 1px;
  text-align: center;
  color: #fff;
  white-space: nowrap;
}

.ngx-datatable .datatable-body .datatable-row-wrapper .more-actions .dropdown-menu {
  top: 0 !important;
  transform: none;
  margin-top: -10px;
  bottom: auto !important;
}

.ngx-datatable .datatable-body .datatable-row-wrapper:last-child .more-actions .dropdown-menu,
.ngx-datatable .datatable-body .datatable-row-wrapper:nth-last-child(2) .more-actions .dropdown-menu {
  top: 0 !important;
  /*bottom: -8px !important;*/
  transform: none;
}

.ngx-datatable .datatable-body .datatable-scroll {
  padding-bottom: 0px;
}

body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-list-item.ui-state-highlight {
  background-color: #f5f5f5 !important;
}

body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-list-item p {
  color: #111111;
}

body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-list-item.ui-state-highlight p:not(.code) {
  color: #111111;
}

body .ui-autocomplete-panel .ui-autocomplete-items .ui-autocomplete-list-item {
  border-bottom: 1px solid #d5d5d5;
}

.search-product {
  border: none !important;
}

.search-product p.code {
  font-size: 12px;
  margin-bottom: 0 !important;
}

.search-product .code.impa {
  color: #444 !important;
  font-weight: 600;
}

.confirmation-box {
  max-width: 446px;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.change-pass {
  max-width: 400px;
}

.change-pass .modal-body {
  padding: 36px 40px 40px;
}

.ui-rating-icon.pi.pi-star {
  color: #f5c518;
}

.ui-rating-icon.pi.pi-star-o:before {
  content: '\e937' !important;
  color: #ccc;
}

body .ui-rating a .ui-rating-icon {
  font-size: 18px;
}

body .ui-autocomplete-panel {
  border: none !important;
}

.confirmation-box .cancelmodal-spc {
  padding: 40px;
}

body .ui-datepicker table td > span.ui-state-disabled {
  opacity: 0.5;
  height: 34px;
  line-height: normal;
  padding: 6px;
}

body .ui-datepicker table td > a.ui-state-active,
body .ui-datepicker table td > a {
  height: 34px;
  line-height: normal;
  padding: 6px;
}

.close-filter-btn {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 15px;
  top: 15px;
  line-height: 25px;
  text-align: center;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  border-radius: 50%;
  text-decoration: none;
  font-weight: lighter;
  cursor: pointer;
  z-index: 3;
}

.close-filter-btn:hover,
.close-filter-btn:focus,
.close-filter-btn:active {
  text-decoration: none;
  color: #fff;
}

.btn:hover {
  color: #fff;
}

.request:hover {
  color: #000;
}

.required-some-data {
  font-size: 14px;
  display: block;
  color: #d80808;
  font-weight: 500;
  margin-bottom: 10px;
}

.modal-open .modal ~ .modal {
  background: rgba(0, 0, 0, 0.6);
}

.scroll-table {
  width: 100%;
  overflow: auto;
}

.full-page-loader {
  position: fixed;
  left: 0;
  top: 0;
  font-size: 35px;
  right: 0;
  bottom: 0;
  justify-content: center;
  font-weight: 700;
  color: #ccc;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 999;
}

.full-page-loader.dark {
  background: rgb(0, 0, 0, 0.4);
  color: #f4f4f5;
  font-size: 30px;
}

.disable-product-popup {
  max-width: 600px;
}

.width100 {
  width: 100%;
}

.tooltips {
  position: relative;
  display: inline-block;
  text-align: left;
}

.tooltips .tooltiptext {
  display: none;
}

.tooltips:hover .tooltiptext {
  display: block;
  width: 300px;
  text-align: left;
  color: #000000;
  border-radius: 6px;
  padding: 5px 20px;
  position: absolute;
  left: -250px;
  word-wrap: break-word;
  top: 30px;
  background: #F9F9F9;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.25);
  z-index: 2;
}

.vendor-attach .view {
  font-size: 12px;
  padding: 0px 10px;
  cursor: pointer;
}

.remarkDiv {
  position: relative;
  display: flex;
  cursor: pointer;
}

.form-control.ng-select.ng-select-disabled .ng-select-container {
  font-size: 13px;
  padding-left: 10px;
}

h5.category-head {
  font-size: 17px;
  font-weight: 600;
  line-height: 0.88;
  letter-spacing: 0.17px;
  padding: 15px 0px 15px;
  border-bottom: solid 1px #b4b4b4;
}

.product-list {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder,
.ng-select.rfq-subcatlist.cat.ng-select-disabled .ng-select-container .ng-value-container .ng-placeholder {
  position: absolute;
  z-index: 1;
  font-size: 13px;
  padding-left: 10px;
}

.ng-select.rfq-subcatlist.cat .ng-select-container .ng-value-container .ng-placeholder {
  padding-left: 0px;
}

.dropdown,
.dropleft,
.dropright,
.dropup {
  display: inline-block;
  float: right;
}

.input-container input {
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: .75rem;
  position: relative;
  width: 100%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option a {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
}

@media (max-width: 1024px) {
  .bottom-line::after {
    max-width: 200px;
  }

  .scroll-table .full-table {
    min-width: 1024px;
  }
}

body .search-categories .error .p-inputtext,
body .search-categories.search-field .error .p-inputtext:enabled:hover:not(.ui-state-error),
.search-categories.search-field .searchbox:hover .ng-autocomplete .p-autocomplete .p-inputtext {
  border-color: #0e76f1 !important;
}

/* .ngx-datatable.table-height .datatable-body {
    min-height: 155px !important;
} */

.search-categories.search-field .searchbox .ng-autocomplete .p-autocomplete .p-inputtext {
  background: #fff !important;
}

.form-group.country-port .form-control {
  min-width: 145px;
  border: 1px solid #d5d5d5;
}

.search-categories.search-field .find-btn.cross-btn {
  right: 31px !important;
  bottom: 1px;
  width: 22px !important;
  color: #888 !important;
  /*  z-index: 2; */
  opacity: 0;
  pointer-events: none;
}

.remark-text {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 110px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.search-categories.search-field .searchbox.showCross .find-btn.cross-btn {
  opacity: 1;
  pointer-events: all;
}

.inner-list {
  width: 160px;
  right: 0;
  top: 55px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease 0s;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  color: #000;
  padding: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  position: absolute;
  z-index: 99;
}

.inner-list.cart {
  top: 45px;
}

.rightmenu > li:hover .inner-list,
.rightmenu > li:hover .inner-list.help-info,
.delete-option:hover .inner-list {
  visibility: visible;
  opacity: 1;
  top: 50px;
}

.delete-option:hover .inner-list {
  bottom: -38px;
  top: unset;
}

.inner-list li {
  width: 100%;
  list-style: none;
}

.inner-list li a {
  display: block;
  padding: 10px 15px;
  color: #444;
  text-align: left;
}

.inner-list li a:hover {
  background-color: #ebf5ff;
  color: #444 !important;
  border-radius: 0px;
}

.datatable-body-cell-label a,
.full-table a {
  word-break: break-word;
}

.card .card-body {
  min-height: 80px;
}

.order-status.ng-select .ng-has-value .ng-placeholder {
  display: block !important;
}

.order-status.ng-select.ng-select-single.ng-touched.ng-dirty .ng-has-value .ng-placeholder {
  display: none !important;
}

.ngx-datatable.material.rfq-table .datatable-body {
  min-height: 445px !important;
  overflow-x: auto !important;
}

.ngx-datatable.material.inventory-table .datatable-body {
  min-height: 300px !important;
}

.ngx-datatable.material.rfq-table .datatable-body .datatable-body-row > div {
  align-items: flex-start !important;
}

.star1 {
  position: relative;
  display: inline-block;
  font-size: 0.92rem;
  cursor: pointer;
  margin: 0 3.5px;
}

.half1 {
  position: absolute;
  display: inline-block;
  overflow: hidden;
}

.fa-star.text-warning {
  color: #f5c518 !important;
}

.fa-star.text-warning.grey {
  color: #ccc !important;
}

span.tag.bg-dark-gray {
  margin-right: 5px;
  margin-bottom: 3px;
}

.tag.bg-dark-gray.budget {
  color: #000;
  padding: 5px 10px;
  margin-top: 18px;
  margin-right: 18px;
  border: 1px solid #ccc;
  border-radius: 6px;
  line-height: 1.2rem;
  letter-spacing: 0.3px;
  background: none;
  min-width: 370px;
}

.tag.bg-dark-gray.budget > label {
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  font-weight: 700;
}

.tag.bg-dark-gray.budget > span {
  color: #0685ec;
}

.tag.bg-dark-gray.budget > .remaining-budget {
  display: flex;
  justify-content: space-between;
  padding-top: 2px;
}

.viewdetail-box.budget-box {
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
}

.viewdetail-box.budget-box .bold-font-size-n {
  font-size: 16px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
  margin: 0;
}

.full-table a {
  word-break: break-word;
}

.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  text-transform: none !important;
  word-break: break-word;
}

.import-btn-div {
  display: flex;
  justify-content: space-between;
}

.hide-cross-btn.ng-select .ng-clear-wrapper {
  color: transparent !important;
  pointer-events: none;
  width: 5px;
}

.sort-order {
  position: absolute;
  right: 22px;
  top: 5px;
  border: 1px solid;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  padding-left: -15px;
  background-color: #0685ec;
  text-align: center;
  line-height: 13px;
  color: #fff;
  cursor: pointer;
}

.loading-spinner {
  display: inline-block;
  pointer-events: none;
  width: 2.2em;
  height: 2.2em;
  border: 0.3em solid transparent;
  border-color: #eee;
  border-top-color: #3E67EC;
  border-radius: 50%;
  animation: loadingspin 0.9s linear infinite;
  -webkit-animation: loadingspin 0.9s linear infinite;
  -moz-animation: loadingspin 0.9s linear infinite;
  -ms-animation: loadingspin 0.9s linear infinite;
  -o-animation: loadingspin 0.9s linear infinite;
}

.loading-spinner.small {
  width: 1.8em;
  height: 1.8em;
  margin-left: 10px;
}

button, a {
  cursor: pointer !important;
}

.loading-spinner.small.dark {
  border-color: #ebebeb;
  border-top-color: #000;
}

.loading-spinner.small.dark.rfq {
  position: relative;
  top: 5px;
}

.loading-spinner.small.dark.inventory {
  position: absolute;
  top: 75px;
  right: 20px;
}

@keyframes loadingspin {
  100% {
    transform: rotate(360deg)
  }
}

.eye-icon-btn {
  background: none;
  outline: none;
  color: #aaa;
  font-size: 15px;
  border: 0;
  position: absolute;
  right: 0;
  top: 64%;
  transform: translateY(-50%);
}

.form-control.p-inputtext ~ .eye-icon-btn {
  top: 30px;
}

.eye-icon-btn:hover,
.eye-icon-btn:active,
.eye-icon-btn:focus {
  outline: none;
}

.eye-icon-btn.user {
  top: 55%;
  right: 20px;
}

.form-group.error .eye-icon-btn.user {
  top: 45%;
}

.form-group.error.notsame .eye-icon-btn.user {
  top: 37%;
}

.form-control.password-field {
  padding-right: 30px !important;
}

.inline-block.pad-bottom {
  margin-bottom: 40px;
}

.inline-block.pad-bottom1 {
  margin-bottom: 42px;
}

.paging-wrap {
  position: absolute;
  top: -30px;
  left: 15px;
  display: flex;
  align-items: center;
}

.paging-wrap.place-bottom {
  position: relative;
  top: -5px;
  left: 0;
  justify-content: flex-end;
}

.add-product.padding20 .paging-wrap {
  top: 75px;
  left: 20px;
}

.paging-wrap.place-bottom.fix {
  position: absolute;
  top: calc(100% - 85px) !important;
  padding-right: 18px;
  right: 0;
}

.total-count {
  font-size: 12px;
  margin-right: 12px;
}

.ngx-datatable.material .datatable-footer .page-count {
  display: inline-block;
  padding-right: 0.9rem !important;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.12px;
  text-align: left;
  color: #000000;
  line-height: 20px !important;
  height: 20px !important;
}

.ngx-datatable.material .datatable-footer .paging .datatable-pager {
  float: none !important;
  margin-left: 0;
}

.ngx-datatable .datatable-footer .datatable-pager .pager li,
.paging-wrap .pagination li {
  display: none !important;
}

.ngx-datatable .datatable-footer .datatable-pager .pager li:nth-child(2),
.ngx-datatable .datatable-footer .datatable-pager .pager li:nth-last-child(2),
.paging-wrap .pagination li:nth-child(2),
.paging-wrap .pagination li:nth-last-child(2) {
  display: inline-block !important;
  border: 0;
}

.ngx-datatable .datatable-footer .datatable-pager .pager li:nth-child(2) {
  margin-right: 5px;
}

.paging-wrap .pagination li:nth-child(2) {
  margin-right: 6px;
}

.ngx-datatable.material .datatable-footer .paging {
  position: absolute;
  top: -35px;
}

.ngx-datatable.material .datatable-footer .paging.place-bottom {
  position: relative;
  top: -5px;
  text-align: right;
}

.ngx-datatable.material .datatable-footer .paging.top-spaces {
  top: -30px;
}

.ngx-datatable.material .datatable-footer .paging.top-space {
  top: -95px;
}

.ngx-datatable.material .datatable-footer .paging.top-space.top-space2 {
  top: -115px;
  width: 40%;
}

.ngx-datatable.material .datatable-footer .datatable-pager a,
.paging-wrap .pagination li a {
  height: 16px !important;
  min-width: 16px !important;
  padding: 0 !important;
  width: 16px !important;
  border-radius: 50px !important;
  margin: 4px 0px 5px !important;
}

.paging-table.ngx-datatable.material .datatable-footer .datatable-pager li.disabled a,
.paging-wrap .pagination li.disabled a {
  color: #000 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  pointer-events: none;
}

.paging-table.ngx-datatable .datatable-footer .datatable-pager .pager li a,
.paging-wrap .pagination li a {
  background-color: #cdcdcd !important;
  box-shadow: none !important;
}

.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-left,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-skip,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-right,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-prev,
.paging-wrap .pagination .page-item .page-link[aria-label*='Previous']::before,
.paging-wrap .pagination .page-item .page-link[aria-label*='Next']::before {
  font-size: 16px !important;
  padding: 0 !important;
  color: #000;
  line-height: 21px !important;
  height: 16px !important;
}

.paging-wrap .pagination .page-item .page-link[aria-label*='Previous']::before,
.paging-wrap .pagination .page-item .page-link[aria-label*='Next']::before {
  line-height: 17px !important;
}

.stop-click.paging-table.ngx-datatable .datatable-footer .datatable-pager .pager li a,
.stop-click.paging-wrap .pagination li a,
.stop-click.ngx-datatable .datatable-footer .datatable-pager .pager li a {
  pointer-events: none !important;
}

.product-img {
  pointer-events: none;
}

.close.cross-butn {
  position: absolute;
  top: 8px;
  right: 8px;
  outline: none;
  width: 25px;
}

.pad-top {
  padding-top: 6px;
}

.close.close1 {
  position: absolute;
  top: 10px;
  right: 12px;
  outline: none;
  width: 25px;
  height: 25px;
}

.close.cross-butn.light {
  top: 1px;
  right: 4px;
}

.close.cross-butn.light span {
  color: #9f9f9f;
  font-size: 30px;
  font-weight: 400;
}

.modal-dialog.gray.modal-small.confirmation-box.new-modal .modal-content {
  border-radius: 10px;
}

.confirmation-box .cancelmodal-spc.empty {
  padding: 42px 40px 61px;
}

.search-categories.search-field .find-btn.cross-btn.cat {
  right: 34px !important;
  bottom: 0;
  width: 22px !important;
  border-radius: 2px 0 0 2px;
  /* opacity: 1; */
}

.search-categories .find-btn.cat {
  right: 8px;
  border: none;
  font-size: 16px;
  width: 25px;
  height: 22px;
  background: transparent;
  top: 9px;
  padding: 0;
  text-align: right;
  border-radius: 0 2px 2px 0;
  padding-right: 4px;
  line-height: 0.8;
}

.req-heading .info-font {
  line-height: normal;
}

.select-wrap {
  position: relative;
  overflow: hidden;
  display: block;
  width: 150px;
}

.select-wrap > i {
  position: absolute;
  right: 12px;
  z-index: 9;
  line-height: 28px;
  color: #adadad;
  cursor: pointer;
  pointer-events: none;
  font-size: 10px;
}

.select-wrap::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 1.2em;
  background: #ebebeb;
  cursor: pointer;
  pointer-events: none;
  height: 28px;
  line-height: 28px;
  border-radius: 0 3px 3px 0;
}

.dropdown.export .dropdown-menu {
  /*transform: translate3d(-118px, 35px, 0px) !important;*/
}

.dropdown.inventory .dropdown-menu {
  /*transform: translate3d(-30px, 40px, 0px) !important;*/
  border-radius: 2px;
  padding: .3rem 0;
  color: #000 !important;
  font-size: 0.9rem;
}

.dropdown.inventory .dropdown-menu .dropdown-item {
  padding: .25rem 1rem;
}

.lottie-loading {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background-color: #fff;
  text-align: center;
  line-height: 100vh;
  font-size: 20px;
}

.lottie-loader {
  width: 210px;
  height: auto;
  background: transparent;
  padding: 15px 10px 10px;
  border-radius: 10px;
  text-align: center;
  /* box-shadow: 4px 6px 15px rgb(0 0 0 / 30%); */
  position: relative;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lottie-loader > img {
  width: 44%;
  position: absolute;
  /* z-index: 9; */
  left: 50%;
  transform: translateX(-50%);
}


/* .lottie-loader>img.hiddenImg {
    opacity: 0;
} */

.lottie-loader h4,
.lottie-loading-h4 {
  font-size: 16px;
  font-weight: 600;
  margin-top: 25px;
  margin-bottom: 0;
  margin-left: 5px;
  color: #000;
}

.lottie-loader h4 {
  margin-top: 85px;
  position: relative;
}

.lottie-loader svg {
  width: 75px !important;
  height: 75px !important;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) !important;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -30vw 0
  }
  100% {
    background-position: 70vw 0
  }
}

.c-animated-background {
  animation-duration: 7.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: fff;
  background: linear-gradient(to right, #9acef7 8%, #0685ec 40%, #9acef7 65%);
  height: 2%;
  width: 100%;
  position: relative;
  padding-top: 5px;
  top: 18px;
  -webkit-backface-visibility: hidden;
  border-radius: 8px;
}

.loaderHide {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.loaderHide.loaderShow {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

button.find-btn.cat.btn.btn-primary.submit-btn {
  text-align: center;
  font-size: 14px;
  color: #fff;
  height: 100%;
  right: 0;
  top: 0;
}

.search-categories.search-field .find-btn.cross-btn.cat.clear {
  right: 124px !important;
  top: 14px;
}

.search-categories.search-field .find-btn.cross-btn.clear {
  padding: 0;
  text-align: center;
  background: #939393;
  color: #fff !important;
  width: 16px !important;
  height: 16px;
  border-radius: 50px;
  font-size: 20px;
  font-weight: 400;
  line-height: 16px;
}

.kyc-tabs .nav-tabs .nav-link {
  padding: 0;
  border: 0;
}

.status-tag.expiring,
.status-tag.expired,
.status-tag.damaged,
.status-tag.low-stock {
  padding: 3px 8px;
  border-radius: 3px;
  color: #fff;
  font-weight: 500;
  white-space: nowrap;
}

/* Container Body */
.body {
  display: flex;
  justify-content: space-evenly;
  padding: 5px 10px;
}

/* Chart Container */
.chart-container {
  height: 250px;
}

/* Cancel Button Hover */
.cancel-btn:hover {
  color: #000;
}

.star-container {
  display: inline-block;
  margin-right: 10px;
  /* Add margin between stars for better visibility */
}

.star {
  font-size: 12px;
  display: inline;
  overflow: hidden;
  color: lightgray;
}

.filled {
  color: gold;
}

.partially-filled {
  content: '\2605';
  color: lightgray;
}

/* Assigned feedback form to user */
.isAssigned {
  background-color: green;
  color: #fff;
  cursor: pointer;
  margin: 0px 5px;
  padding: 0px 5px;
}

.isAssignedText {
  color: rgb(88, 204, 88);

}

.no-data-header {
  font-weight: 800;
  color: darkgray;
  opacity: 0.8;
  font-size: 20px;
}


.font-weight-600 {
  font-weight: 600;
}

.border {
  border: 1px solid rgba(0, 0, 0, .2);
}

.menu-icon {
  opacity: .4;
}

.material-symbols-outlined {
  font-variation-settings: 'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}

.font10{
  font-size: 12px;
}

.company-specific-input .custom-form.ng-select .ng-select-container{
  border-radius: 2px !important;
  border: 1px solid #dee2e6 !important;
}

.company-specific-input .ng-select.ng-select-single .ng-select-container{
  height: 35px !important;
  min-height: unset !important;
}

/*.p-inputtext{*/
/*  font-size: 10px !important;*/
/*  font-weight : 400 !important;*/
/*  background: transparent !important;*/
/*  padding: 0 !important;*/
/*  border: 0 !important;*/
/*  color: #444 !important;*/
/*  cursor: pointer;*/
/*}*/

.p-autocomplete-input{
  width : 100% !important;
}

.p-calendar{
  width : 100% !important;
}

body .p-autocomplete p{
  line-height: 1.5 !important;
  margin: 0 !important;
  font-family: "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.p-float-label>input:-webkit-autofill ~ label {
  top: -.75em;
  font-size: 12px;
}


.p-float-label>label {
  left: 4px !important;
  color: #666;
  transition-duration: .2s;
}

.p-float-label input:focus~label, .p-float-label input.p-filled~label,
.p-float-label textarea:focus~label, .p-float-label textarea.p-filled~label,
.p-float-label .p-inputwrapper-focus~label, .p-float-label .p-inputwrapper-filled~label{
  top: -.4em !important;
}

.user-login-table-column-header {
  font-weight: bold !important;
  text-align: center !important;
  background-color: aliceblue !important;
}

.user-login-table-column-cell {
  justify-content: center !important;
  text-align: center !important;
}


.filter-selected {
  font-weight: 500;
  background-color: rgb(73 133 190);
  border-radius: 3px;
  padding: 5px;
  /* margin:  0px 2px; */
  color: white;
  font-size: 11px;
  opacity: 0.7;
  position: relative;
}

.cross-icon {
  width: 15px;
  height: 15px;
  position: absolute;
  /* right: 0; */
  top: -10px;
  border: 2px solid #000;
  border-radius: 100%;
  /* color: red; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  background: #fff;
}

.cross-button ,.cross-button:hover {
  color: #000;
  font-size: 12px;
  text-decoration: none;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{
  margin-bottom: 0 !important;
}

/* To prevent  */
.user-select{
  user-select: none;
}

.reward-box {
  width: 268px;
  height: 104px;
  padding: 10px 10px 12px 12px;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(198, 198, 198, 0.08);
  border: solid 0.5px #D4E8FF;
  background-color: #fff;
  margin-right: 20px;
  position: relative;
}

.reward-box p {
  font-size: 10px;
  line-height: 1.3;
  color: #414141;
  margin-bottom: 14px;
  padding: 2px 10px;
  border-radius: 5px;
}

.reward-box h5 {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.25;
  margin: 0 0 4px;
}

.reward-box h2 {
  color: #0074F2;
  font-weight: 700;
  border-bottom: 2px solid #C1C1C1;
  width: 60%;
}

.reward p {
  height: 30px;
  background-color: #D4E8FF;
}

.reward-box p>span {
  font-weight: 700;
}
.point p{
  padding: 5px 0px 5px 0px;
}

.rewards-point{
  font-weight: 700;
  color: #0074F2;
}
.vendor-wrapper>div.product-details {
  width: 43.5%;
}

.vendor-wrapper>div.product-details.vendor-details {
  width: 55.5%;
}

.graph-wrapper,
.vendor-wrapper {
  display: flex;
  justify-content: space-between;
}

.graph-wrapper>div {
  width: 49.5%;
  margin-bottom: 10px;
}
.cal-footer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;
}

.custom-time {
  display: flex;
  gap: 35px;
  align-items: center;
}

.custom-time label {
  font-weight: bold;
  margin-right: 5px;
}

.custom-time input {
  width: 60px;
  padding: 5px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
}

.custom-time input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.date-of-enquiry-calendar .p-timepicker {
  border-top: 1px solid #d8dae2;
  padding: 0.857rem 0 0.429rem 0;
  display: none;
}

.isPriceAvailable{
  background-color: rgb(0 181 45 / 14%);;
}

.isInventoryQty{
  color: rgb(198, 81, 81);
}

.isNeverInventoryAvailable{
  background-color: #ce24392e;
}

.isNotInventoryAvailable{
  background-color: #ffa26036;
}

.warehouse-quant{
  border: 0;
  font-size: 9px;
  color: #000;
  text-transform: capitalize;
  border-radius: 2px;
  padding: 0px 0px 0px 0px;
  margin-bottom: 0;
}

/* General Progress Bar Styling */
progress {
  width: 100%;
  height: 8px; /* Adjust the height */
  border: none;
  appearance: none; /* Remove default styles */
}

/* For WebKit Browsers (Chrome, Safari) */
progress::-webkit-progress-bar {
  background-color: #ddd; /* Background color */
  border-radius: 5px;
  height: 8px;
}

progress::-webkit-progress-value {
  border-radius: 5px;
  height: 8px;
}

/* For Firefox */
progress::-moz-progress-bar {
  border-radius: 5px;
  height: 8px;
}

/* Green for Available */
.isPriceAvailable::-webkit-progress-value {
  background-color:rgb(0 181 45 / 14%);
}

.isPriceAvailable::-moz-progress-bar {
  background-color:rgb(0 181 45 / 14%);
}

/* Orange for Not Available */
.isNotInventoryAvailable::-webkit-progress-value {
  background-color: #ffa26036;
}

.isNotInventoryAvailable::-moz-progress-bar {
  background-color: #ffa26036;
}

/* Red for Never Available */
.isNeverInventoryAvailable::-webkit-progress-value {
  background-color: #ce24392e;
}

.isNeverInventoryAvailable::-moz-progress-bar {
  background-color: #ce24392e;
}

.label-qty{
  font-size: 12px;
  font-weight: 500;
}
/* Truccate text: if text is big show (...) after use this add title attribute to show full name */
.truncated-text {
  max-width: 100px; /* Ensure a fixed width */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}